import { atom } from "jotai";

const navbarValueAtom = atom(0);
const mapAtom = atom(null);
const campPointsAtom = atom([]);
const boundsAtom = atom([]);
const userAtom = atom({ user: {}, username: "jesse" });
const mapListToggleAtom = atom(false);
const modalLoginAtom = atom(false);
const modalWatchlistAtom = atom(false);
const watchlistToggleAtom = atom(false);
const navSelectionAtom = atom("list-map");
const nearbyRecGovResponseAtom = atom(null);
const loadingSpinnerAtom = atom(false);
const stripeProductSelectionAtom = atom(null);
const navBarToggleButtonStateAtom = atom(null);
const prevPathAtom = atom(null);

const filterAtom = atom({
  tent: false,
  rv: false,
  stateSite: false,
  federalSite: false,
  countySite: false,
  ada: false,
});
const mobileFooterHeightAtom = atom(65);
const footerHeightAtom = atom(60);
const navBarHeightAtom = atom(70);

export {
  mapAtom,
  campPointsAtom,
  boundsAtom,
  userAtom,
  mapListToggleAtom,
  watchlistToggleAtom,
  modalLoginAtom,
  navSelectionAtom,
  nearbyRecGovResponseAtom,
  modalWatchlistAtom,
  filterAtom,
  navbarValueAtom,
  mobileFooterHeightAtom,
  footerHeightAtom,
  navBarHeightAtom,
  loadingSpinnerAtom,
  stripeProductSelectionAtom,
  navBarToggleButtonStateAtom,
  prevPathAtom,
};
