// import { Stripe, loadStripe } from "@stripe/stripe-js/pure";
import { loadStripe } from "@stripe/stripe-js";

// Stripe.js will not be loaded until `loadStripe` is called

const initializeStripe = async () => {
  const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  return stripe;
};

export default initializeStripe;
