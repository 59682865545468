import React, { useEffect } from "react";
import { Button } from "@mui/material";
import GoogleLogo from "./icons/google-logo";
import { NextLinkComposed } from "./nextjs-link";
import { auth, googleAuthProvider } from "../utils/firebase";
import { useRouter } from "next/router";
import { useAtom } from "jotai";
import useUserData from "../utils/hooks/use-user-data";
import useMediaQuery from "@mui/material/useMediaQuery";
import useSubscriptionStatus from "../utils/stripe/useSubscriptionStatus";
import {
  modalLoginAtom,
  loadingSpinnerAtom,
  stripeProductSelectionAtom,
  prevPathAtom,
} from "../utils/store";
import createCheckoutSession from "../utils/stripe/createCheckoutSession";
import initializeStripe from "../utils/stripe/initializeStripe";

export default function ButtonLoginGoogle({ handleMenuClose }) {
  const router = useRouter();
  const smDown = useMediaQuery("(max-width:599px)", { noSsr: true });
  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const [loadingSpinner, setLoadingSpinner] = useAtom(loadingSpinnerAtom);
  const [prevPath, setPrevPath] = useAtom(prevPathAtom);
  const { user, username } = useUserData();

  const subStatus = useSubscriptionStatus(user);

  const [productSelection, setProductSelection] = useAtom(
    stripeProductSelectionAtom
  );

  // Sign in with Google button
  const signInWithGoogle = async () => {
    const routeToTrial = async (user) => {
      await auth.currentUser.getIdToken(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      console.log("decodedtoken", decodedToken.claims.subscriptionRole);
      if (
        decodedToken?.claims?.subscriptionRole == "basic" ||
        decodedToken?.claims?.subscriptionRole == "standard" ||
        decodedToken?.claims?.subscriptionRole == "pro"
      ) {
        router.push("/search");
      } else {
        setTimeout(() => {
          createCheckoutSession(
            "/api/checkout-login-redirect-trial",
            process.env.STRIPE_SUB_PRO,
            user.uid
          );
        }, "1500");
      }
    };
    handleMenuClose && handleMenuClose();
    setModalLogin(false);
    try {
      // function getClientReferenceId() {
      //   if (typeof window !== "undefined") {
      //     return window.Rewardful && window.Rewardful.referral;
      //   }
      //   return null;
      // }
      await auth.signInWithPopup(googleAuthProvider);
      auth.onAuthStateChanged((user) => {
        if (user) {
          setLoadingSpinner(true);
          if (smDown) {
            if (prevPath == "/pricing") {
              if (productSelection == null || productSelection == undefined) {
                router.push("/search");
              } else {
                createCheckoutSession(
                  "/api/checkout-login-redirect",
                  productSelection,
                  user.uid
                  // getClientReferenceId()
                );
              }
            } else if (prevPath == "/trial") {
              routeToTrial(user);
            } else {
              router.push("/search");
            }
          }
          if (router.pathname == "/pricing") {
            if (productSelection == null || productSelection == undefined) {
              router.push("/search");
            } else {
              createCheckoutSession(
                "/api/checkout-login-redirect",
                productSelection,
                user.uid
                // getClientReferenceId()
              );
            }
          } else if (router.pathname == "/trial") {
            routeToTrial(user);
          } else {
            router.push("/search");
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      onClick={signInWithGoogle}
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "193px",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingRight: "12px",
        textTransform: "lowercase",
        color: "black",
        background: "white",
        boxShadow:
          "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        border: "1px solid #DDDDDD !important",
        "&:hover": {
          border: "1px solid black !important",
        },
      }}
      // component={NextLinkComposed}
      // to={{
      //   pathname: user && "/search",
      // }}
      startIcon={<GoogleLogo sx={{ fontSize: "18px !important" }} />}
    >
      <span style={{ textTransform: "capitalize" }}>Continue</span>
      &nbsp;with&nbsp;
      <span style={{ textTransform: "capitalize" }}>Google</span>
    </Button>
  );
}
