import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { UserContext } from "../utils/context";
import useUserData from "../utils/hooks/use-user-data";
import { DefaultSeo } from "next-seo";
import CssBaseline from "@mui/material/CssBaseline";
import Div100vh from "react-div-100vh";
import { useWindowHeight } from "@react-hook/window-size";
import { useRouter } from "next/router";
import Navbar from "./navbar";
import { use100vh, measureHeight } from "react-div-100vh";
import LoginModal from "./modal-login";

export default function Layout({ children }) {
  const smDown = useMediaQuery("(max-width:599px)");
  const userData = useUserData();
  const router = useRouter();
  const height = use100vh();
  const heightMeasured = measureHeight();

  return (
    <UserContext.Provider value={userData}>
      <DefaultSeo
        title="Campwatch | Campground availability alerts"
        description="Campwatch monitors campgrounds for availability, and notifies you when the perfect spot opens up"
        openGraph={{
          url: "https://www.campwatch.co",
          title: "Campwatch | Campground availability alerts",
          description:
            "Campwatch monitors campgrounds for availability, and notifies you when the perfect spot opens up.",
          site_name: "Campwatch",
          images: [
            {
              url: "https://firebasestorage.googleapis.com/v0/b/crud-test-4a01b.appspot.com/o/assets%2Fcamping.jpg?alt=media&token=0276430c-ab28-4ed2-a5b5-73cddee64b61",
              width: 1200,
              height: 630,
              alt: "Camping",
              type: "image/jpeg",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/crud-test-4a01b.appspot.com/o/assets%2Fcampwatch-og-square.jpeg?alt=media&token=56fb2579-d117-4f6d-ab52-b9b77472ebc7",
              width: 600,
              height: 600,
              alt: "Camping",
              type: "image/jpeg",
            },
          ],
        }}
        additionalLinkTags={[
          {
            rel: "mask-icon",
            color: "#5bbad5",
            href: "/safari-pinned-tab.svg",
          },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
          },
          {
            rel: "icon",
            href: "/favicon-32x32.png",
            sizes: "32x32",
          },
          {
            rel: "icon",
            href: "/favicon-16x16.png",
            sizes: "16x16",
          },
          {
            rel: "manifest",
            href: "/site.webmanifest",
          },
        ]}
        additionalMetaTags={[
          {
            name: "msapplication-TileColor",
            content: "#ffffff",
          },
          {
            name: "theme-color",
            content: "#ffffff",
          },
        ]}
      />

      <CssBaseline />
      <div
        style={{
          height: height,
        }}
      >
        {children}
        <LoginModal />
      </div>
    </UserContext.Provider>
  );
}
