import * as React from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

function Tent({ props }) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_201_2)">
          <path
            d="M348.001 70.001C294.931 92.825 256.041 89.843 195.002 70.001L342.001 372.001H502L348.001 70.001Z"
            fill="#FEDC75"
          />
          <path d="M502 372H342V442H502V372Z" fill="#FFB571" />
          <path
            d="M268.001 442.001L195.001 321.001L122.001 442.001H268.001Z"
            fill="#6E6E6E"
          />
          <path
            d="M195.001 70.001L50.002 372.001V442.001H122.001L195.001 321.001V70.002V70.001Z"
            fill="#FFB571"
          />
          <path
            d="M195.001 70.002V321.001L268.001 442.001H342.001V372.001L195.001 70.002Z"
            fill="#FFB571"
          />
          <path
            d="M195.001 321.001L268.001 442.001V338.001L195.001 321.001Z"
            fill="#FEDC75"
          />
          <path
            d="M122.001 442.001L195.001 321.001L122.001 335.001V442.001Z"
            fill="#FEDC75"
          />
          <path
            d="M510.908 367.458L356.909 65.4581C354.516 60.7651 348.892 58.7331 344.05 60.8141C296.811 81.1301 261.344 81.0501 198.089 60.4901C193.464 58.9911 188.094 61.2851 185.987 65.6731L41.1432 367.349L1.31917 437.04C-1.42083 441.835 0.245173 447.943 5.04117 450.684C9.78217 453.395 15.9842 451.689 18.6842 446.962L40.0012 409.657V442.001C40.0012 447.523 44.4792 452.001 50.0012 452.001H502C507.522 452.001 512 447.523 512 442.001C512 442.001 512 372.029 512 372.001C512 371.783 511.52 368.657 510.908 367.458ZM112.002 335.001V432.001H60.0022V374.277L185.002 113.932V312.736L120.118 325.179C115.408 326.083 112.002 330.204 112.002 335.001ZM132.001 343.265L174.847 335.048L132.001 406.066V343.265ZM139.714 432.001L195.002 340.36L250.29 432.001H139.714ZM258.001 406.067L215.797 336.111L258.001 345.939V406.067ZM332.001 432.001H278.001V338.001C278.001 333.352 274.797 329.316 270.27 328.262L205.002 313.063V113.394L332.002 374.305V432.001H332.001ZM241.764 143.223C247.401 144.503 253.807 145.804 260.631 146.911C265.994 147.803 271.24 143.984 272.1 138.64C272.98 133.19 269.27 128.05 263.83 127.17C253.17 125.44 243.49 123.16 237.24 121.54C234.839 120.926 232.524 120.29 230.29 119.653L214.018 86.2201C259.332 98.8041 296.666 100.802 343.271 82.7391L485.676 362.002H348.255L241.764 143.223ZM352.001 432.001V401.336L375.656 432.001H352.001ZM492 432.001H400.916L362.345 382.001H492V432.001Z"
            fill="black"
          />
          <path
            d="M302.09 149.2C307.613 149.2 312.09 144.723 312.09 139.2C312.09 133.677 307.613 129.2 302.09 129.2C296.567 129.2 292.09 133.677 292.09 139.2C292.09 144.723 296.567 149.2 302.09 149.2Z"
            fill="black"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default function TentTanIcon() {
  return (
    <Box
      sx={{
        "& > :not(style)": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "25px",
        },
      }}
    >
      <Tent />
    </Box>
  );
}
