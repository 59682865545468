import React, { useEffect, useState } from "react";
import { useCollection } from "../utils/hooks/use-collection";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";
// import useUserData from "../utils/hooks/use-user-data";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { auth, googleAuthProvider, appleAuthProvider } from "../utils/firebase";
import Link from "./nextjs-link";
import { IconButton } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { NextLinkComposed } from "./nextjs-link";
import MegaphoneIcon from "./icons/megaphone-color";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import useSubscriptionStatus from "../utils/stripe/useSubscriptionStatus";
import useUserData from "../utils/hooks/use-user-data";
import { firestore } from "../utils/firebase";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";

import {
  modalLoginAtom,
  watchlistToggleAtom,
  modalWatchlistAtom,
} from "../utils/store";
import DropdownAccordion from "./account-popover-dropdown";

export default function AccountPopover({ smDown, user }) {
  // const { user, username } = useUserData();
  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const [watchListToggle, setWatchListToggle] = useAtom(watchlistToggleAtom);
  const [openWatchlist, setOpenWatchlist] = useAtom(modalWatchlistAtom);
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const subStatus = useSubscriptionStatus(user);

  const { documents, error } = useCollection("watchlist", [
    "uid",
    "==",
    user?.uid,
  ]);

  const linkPath = () => {
    if (user) {
      return "/alerts";
    }
    return "/login";
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ marginTop: "3px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user && (
        <Box>
          <Box sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
            <Stack
              sx={{
                border: "1px solid lightgray",
                marginBottom: "16px",
                marginTop: "8px",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <Stack direction="column">
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  {documents && documents.length}/{subStatus.limit}
                </Typography>
                <Typography>active alerts running</Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                  marginBottom: "10px",
                }}
              >
                {user.email}
              </Typography>
            </Stack>
          </Box>
          <MenuItem
            component={NextLinkComposed}
            to={{
              pathname: "/account",
            }}
            onClick={handleMenuClose}
          >
            <ListItemIcon>
              <SettingsIcon sx={{ color: "black" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText>Account</ListItemText>
          </MenuItem>
          <MenuItem
            component={NextLinkComposed}
            to={{
              pathname: linkPath(),
            }}
            onClick={handleMenuClose}
          >
            <ListItemIcon>
              <MegaphoneIcon fontSize="small" />

              {/* <MegaphoneIcon fillColor="black" fontSize="small" /> */}
            </ListItemIcon>
            <ListItemText>Alert List</ListItemText>
          </MenuItem>
          {/* <MenuItem
            component={NextLinkComposed}
            to={{
              pathname: "/affiliates",
            }}
            onClick={handleMenuClose}
          >
            <ListItemIcon>
              <AttachMoneyRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Affiliates</ListItemText>
          </MenuItem> */}
        </Box>
      )}
      <Stack alignItems="center">
        <SignInSignUpSignOutButton
          router={router}
          handleMenuClose={handleMenuClose}
          user={user}
          setModalLogin={setModalLogin}
        />
      </Stack>
    </Menu>
  );

  let buttonStyle;

  if (router.pathname === "/search") {
    buttonStyle = {
      background: "white",
      borderRadius: "30px",
      ".MuiButton-startIcon": {
        marginRight: 0,
      },
      border: "none",
      boxShadow:
        "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
      "&:hover": {
        boxShadow: "0px 2px 4px rgb(0 0 0 / 25%) !important",
        border: "none",
        background: "white",
      },
    };
  } else {
    buttonStyle = {
      background: "white",
      borderRadius: "30px",
      ".MuiButton-startIcon": {
        marginRight: 0,
      },
      border: "1px solid #DDDDDD!important",
      boxShadow:
        "0px 1px 2px rgb(0 0 0 / 8%),0px 4px 12px rgb(0 0 0 / 5%)!important",
      "&:hover": {
        boxShadow:
          "0px 1px 2px rgb(0 0 0 / 8%),0px 4px 12px rgb(0 0 0 / 5%)!important",
        border: "1px solid black!important",
        background: "white",
      },
    };
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: { sm: "0px", md: "30px" },
      }}
    >
      {smDown && router.pathname === "/search" && (
        <IconButton
          sx={{ paddingRight: "12px" }}
          onClick={handleProfileMenuOpen}
        >
          <Avatar
            sx={{
              width: 30,
              height: 30,
              fontSize: "16px",
            }}
            alt={user && user.displayName}
            src={user && user.photoURL}
          />
        </IconButton>
      )}
      {(smDown && !router.pathname === "/search") ||
        (!smDown && (
          <Button
            variant="outlined"
            sx={buttonStyle}
            startIcon={<MenuRoundedIcon sx={{ color: "black" }} />}
            onClick={handleProfileMenuOpen}
          >
            <Avatar
              sx={{
                left: "10px",
                width: 30,
                height: 30,
                fontSize: "16px",
              }}
              alt={user && user.displayName}
              src={user && user.photoURL}
            />
          </Button>
        ))}
      {renderMenu}
    </Box>
  );
}

// Sign in/Sign Up button
function SignInSignUpSignOutButton({
  handleMenuClose,
  user,
  setModalLogin,
  router,
}) {
  const handleSignOut = () => {
    handleMenuClose();
    auth.signOut();
    router.push("/");
  };
  const handleLogin = () => {
    setModalLogin(true);
    handleMenuClose();
  };
  return (
    <Button
      variant="outlined"
      sx={{
        // width: "fit-content",
        width: "150px",
        // marginLeft: "16px",
        // paddingRight: "16px",
        // marginRight: "16px",
        marginBottom: "8px",
        marginTop: "16px",
        boxShadow:
          "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        border: "1px solid #DDDDDD !important",
        color: "black",
        "&:hover": {
          color: "black",
          border: "1px solid black !important",
        },
      }}
      className="btn-signin-signup"
      onClick={() => (user ? handleSignOut() : handleLogin())}
    >
      {user ? "Sign Out" : "Sign In/Sign Up"}
    </Button>
  );
}
