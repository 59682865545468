import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import { navbarValueAtom } from "../utils/store";
import Image from "next/image";
import useUserData from "../utils/hooks/use-user-data";
import AccountPopover from "./account-popover";
import { NextLinkComposed } from "./nextjs-link";
import ButtonGetStarted from "./button-get-started";
import AccountPopoverLoggedOut from "./account-popover-logged-out";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAtom } from "jotai";
import {
  navBarHeightAtom,
  modalLoginAtom,
  navBarToggleButtonStateAtom,
} from "../utils/store";
import useSubscriptionStatus from "../utils/stripe/useSubscriptionStatus";

import { ToggleButton } from "@mui/material";
import * as gtag from "../utils/gtag";

export default function Navbar({ inView }) {
  const smDown = useMediaQuery("(max-width:600px)");
  const [navbarValue, setNavbarValue] = useAtom(navbarValueAtom);
  const [navBarToggleButtonState, setNavBarToggleButtonState] = useAtom(
    navBarToggleButtonStateAtom
  );

  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const { user, authIsReady } = useUserData();

  const subStatus = useSubscriptionStatus(user);

  const router = useRouter();
  const [navBarHeight, setNavBarHeight] = useAtom(navBarHeightAtom);

  useEffect(() => {
    setNavBarToggleButtonState(router.pathname);
  }, []);

  const checkPathnameValue = () => {
    if (!router.isReady) return;
    const path = router.pathname;
    switch (path) {
      case "/faq":
      case "/pricing":
      case "/about":
      case "/alerts":
        break;
      default:
        return false;
    }
    return path;
  };

  const handleLogin = () => {
    gtag.click(`clicked_login`);
    if (user) {
      router.push("/search");
    } else {
      smDown ? router.push("/login") : setModalLogin(true);
    }
  };

  const linkPath = () => {
    if (user && authIsReady) {
      // if (subStatus) {
      //   return "/alerts";
      // }
      return "/alerts";
    }
    return "/login";
  };

  return (
    <Stack
      maxWidth="lg"
      sx={{
        height: navBarHeight,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: { xs: "16px" },
        paddingRight: { xs: "16px" },
        boxShadow: {
          xs:
            inView !== undefined && !inView
              ? "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)"
              : "none",
          sm: "none",
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            position: "relative",
            height: { xs: "28px", sm: "35px" },
            width: { xs: "120px", sm: "150px" },
            cursor: "pointer",
          }}
          component={NextLinkComposed}
          to={{
            pathname: user ? "/search" : "/",
          }}
        >
          <Image layout="fill" src="/logo-campwatch.svg" alt="Campwatch logo" />
        </Box>
        {!smDown && !user && (
          <>
            <ToggleButton
              sx={{
                marginLeft: "11px",
                border: "none",
                background: "white",
                "&.Mui-selected": {
                  background: "white",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
              value="/faq"
              selected={router.pathname === "/faq"}
              onClick={() => {
                if (navBarToggleButtonState === "/faq") return;
                setNavBarToggleButtonState("/faq");
                router.push("/faq");
              }}
            >
              <span
                style={{
                  paddingBottom: "2px",
                  borderBottom:
                    navBarToggleButtonState === "/faq"
                      ? "3px solid black"
                      : "3px solid transparent",
                }}
              >
                FAQ
              </span>
            </ToggleButton>
            <ToggleButton
              sx={{
                border: "none",
                background: "white",
                "&.Mui-selected": {
                  background: "white",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
              value="/pricing"
              selected={router.pathname === "/pricing"}
              onClick={() => {
                if (navBarToggleButtonState === "/pricing") return;
                setNavBarToggleButtonState("/pricing");
                router.push("/pricing");
              }}
            >
              <span
                style={{
                  paddingBottom: "2px",
                  borderBottom:
                    navBarToggleButtonState === "/pricing"
                      ? "3px solid black"
                      : "3px solid transparent",
                }}
              >
                Pricing
              </span>
            </ToggleButton>
            <ToggleButton
              sx={{
                border: "none",
                background: "white",
                "&.Mui-selected": {
                  background: "white",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
              value="/about"
              selected={router.pathname === "/about"}
              onClick={() => {
                if (navBarToggleButtonState === "/about") return;
                setNavBarToggleButtonState("/about");
                router.push("/about");
              }}
            >
              <span
                style={{
                  paddingBottom: "2px",
                  borderBottom:
                    navBarToggleButtonState === "/about"
                      ? "3px solid black"
                      : "3px solid transparent",
                }}
              >
                About
              </span>
            </ToggleButton>
          </>
        )}
        {!smDown && user && (
          <>
            <ToggleButton
              sx={{
                marginLeft: "11px",
                border: "none",
                background: "white",
                "&.Mui-selected": {
                  background: "white",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
              value="/alerts"
              selected={router.pathname === "/alerts"}
              onClick={() => {
                if (navBarToggleButtonState === "/alerts") return;
                setNavBarToggleButtonState(linkPath());
                router.push(linkPath());
              }}
            >
              <span
                style={{
                  paddingBottom: "2px",
                  borderBottom:
                    navBarToggleButtonState === "/alerts"
                      ? "3px solid black"
                      : "3px solid transparent",
                }}
              >
                Alerts
              </span>
            </ToggleButton>
            <ToggleButton
              sx={{
                border: "none",
                background: "white",
                "&.Mui-selected": {
                  background: "white",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
              value="/search"
              selected={router.pathname === "/search"}
              onClick={() => {
                if (navBarToggleButtonState === "/search") return;
                setNavBarToggleButtonState("/search");
                router.push("/search");
              }}
            >
              <span
                style={{
                  paddingBottom: "2px",
                  borderBottom:
                    navBarToggleButtonState === "/search"
                      ? "3px solid black"
                      : "3px solid transparent",
                }}
              >
                Map
              </span>
            </ToggleButton>
          </>
        )}
      </Box>
      {user ? (
        <AccountPopover user={user} authIsReady={authIsReady} />
      ) : !smDown ? (
        <>
          <Box>
            <Button
              onClick={handleLogin}
              sx={{ color: "black", marginRight: "16px" }}
            >
              log in
            </Button>
            <ButtonGetStarted noIcon title="Sign Up" user={user && user} />
          </Box>
        </>
      ) : (
        <AccountPopoverLoggedOut
          user={user && user}
          authIsReady={authIsReady}
        />
      )}
    </Stack>
  );
}
