import * as React from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

function GoogleLogo(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M113.47 309.408L95.648 375.94L30.509 377.318C11.042 341.211 0 299.9 0 256C0 213.549 10.324 173.517 28.624 138.268H28.638L86.63 148.9L112.034 206.544C106.717 222.045 103.819 238.685 103.819 256C103.821 274.792 107.225 292.797 113.47 309.408Z"
          fill="#FBBB00"
        />
        <path
          d="M507.527 208.176C510.467 223.662 512 239.655 512 256C512 274.328 510.073 292.206 506.402 309.451C493.94 368.134 461.377 419.376 416.268 455.638L416.254 455.624L343.21 451.897L332.872 387.362C362.804 369.808 386.196 342.337 398.518 309.451H261.628V208.176H400.515H507.527Z"
          fill="#518EF8"
        />
        <path
          d="M416.253 455.624L416.267 455.638C372.396 490.901 316.666 512 256 512C158.509 512 73.748 457.509 30.509 377.319L113.47 309.409C135.089 367.107 190.748 408.18 256 408.18C284.047 408.18 310.323 400.598 332.87 387.362L416.253 455.624Z"
          fill="#28B446"
        />
        <path
          d="M419.404 58.936L336.471 126.832C313.136 112.246 285.552 103.82 256 103.82C189.271 103.82 132.571 146.777 112.035 206.544L28.638 138.268H28.624C71.23 56.123 157.06 0 256 0C318.115 0 375.068 22.126 419.404 58.936Z"
          fill="#F14336"
        />
      </svg>
    </SvgIcon>
  );
}

export default function GoogleLogoIcon() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GoogleLogo sx={{ fontSize: "18px" }} />
    </Box>
  );
}
