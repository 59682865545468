import * as React from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

function Megaphone({ props }) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="248.489"
          y="31"
          width="279.104"
          height="55"
          transform="rotate(44.4103 248.489 31)"
          fill="#AAA8A7"
        />
        <rect
          x="204.685"
          y="362.727"
          width="135.519"
          height="55.3855"
          transform="rotate(44.5257 204.685 362.727)"
          fill="#827E7D"
        />
        <rect
          x="219.182"
          y="341.124"
          width="134.84"
          height="110"
          transform="rotate(136.753 219.182 341.124)"
          fill="#AAA8A7"
        />
        <rect
          x="363"
          y="219.479"
          width="59.2869"
          height="58.267"
          transform="rotate(-33.2181 363 219.479)"
          fill="#827E7D"
        />
        <rect
          x="92.8376"
          y="410.823"
          width="139"
          height="31.8224"
          transform="rotate(-45.07 92.8376 410.823)"
          fill="#827E7D"
        />
        <path
          d="M226 93.5L377 248.5L270.5 297L226 336L144.5 248.5L199.5 176.5L226 93.5Z"
          fill="#E8C96C"
          stroke="black"
        />
        <path
          d="M226 331.5L375 249.5L339.5 211L195.5 305.5L226 331.5Z"
          fill="#D5B964"
        />
        <path
          d="M446.977 201.914L277.316 32.253C259.777 14.713 231.235 14.712 213.694 32.253C197.926 48.02 196.568 72.067 208.212 89.238L207.976 90.419C195.744 151.584 165.965 207.228 121.856 251.336L44.037 329.155C26.455 346.738 26.452 375.194 44.037 392.777L86.451 435.191C104.032 452.773 132.49 452.774 150.073 435.191L160.677 424.587L234.903 498.813C252.485 516.396 280.941 516.397 298.524 498.813C316.065 481.273 316.065 452.732 298.524 435.192L266.713 403.381L277.317 392.777C294.899 375.196 294.901 346.74 277.317 329.156L270.111 321.949C305.56 297.13 345.819 279.851 388.812 271.252L390.008 271.013C407.561 282.864 431.552 280.959 446.977 265.534H446.978C464.516 247.995 464.516 219.455 446.977 201.914ZM128.866 413.984C123.006 419.843 113.518 419.844 107.658 413.983L65.244 371.569C59.383 365.707 59.383 356.223 65.244 350.362L139.469 276.136L203.091 339.758C195.829 347.02 136.307 406.542 128.866 413.984ZM277.316 456.398C283.162 462.244 283.162 471.758 277.316 477.605C271.47 483.451 261.955 483.451 256.109 477.605L181.883 403.379L203.091 382.172L277.316 456.398ZM224.298 360.965C228.806 356.457 235.554 349.552 246.204 340.456L256.109 350.362C261.97 356.223 261.97 365.707 256.109 371.569L245.505 382.173L224.298 360.965ZM224.935 319.189L160.037 254.291C194.816 214.132 219.722 166.727 233.047 115.227L363.999 246.179C312.499 259.503 265.095 284.409 224.935 319.189ZM425.767 244.326C419.906 250.186 410.421 250.186 404.56 244.326L234.901 74.667C229.04 68.805 229.04 59.321 234.901 53.46C240.762 47.599 250.248 47.599 256.109 53.46L425.767 223.118C431.613 228.965 431.613 238.478 425.767 244.326Z"
          fill="black"
        />
        <path
          d="M150.072 329.154C144.217 323.298 134.721 323.298 128.865 329.154L107.658 350.361C101.802 356.217 101.802 365.712 107.658 371.568C113.513 377.424 123.01 377.424 128.865 371.568L150.072 350.361C155.928 344.505 155.928 335.01 150.072 329.154Z"
          fill="black"
        />
        <path
          d="M346.19 0C337.908 0 331.194 6.714 331.194 14.996V44.988C331.194 53.27 337.908 59.984 346.19 59.984C354.472 59.984 361.186 53.27 361.186 44.988V14.996C361.186 6.714 354.472 0 346.19 0Z"
          fill="black"
        />
        <path
          d="M466.157 119.966H436.165C427.883 119.966 421.169 126.68 421.169 134.962C421.169 143.244 427.883 149.958 436.165 149.958H466.157C474.439 149.958 481.153 143.244 481.153 134.962C481.153 126.68 474.438 119.966 466.157 119.966Z"
          fill="black"
        />
        <path
          d="M446.769 34.383C440.914 28.527 431.418 28.527 425.562 34.383L395.57 64.375C389.714 70.231 389.714 79.726 395.57 85.582C401.425 91.438 410.921 91.439 416.777 85.582L446.769 55.59C452.625 49.734 452.625 40.24 446.769 34.383Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}

export default function MegaphoneIcon() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Megaphone />
    </Box>
  );
}
