import * as React from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

function AppleLogo(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 513 513" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_28)">
          <path
            d="M351.98 0C324.684 1.888 292.78 19.36 274.188 42.112C257.228 62.752 243.276 93.408 248.716 123.2C278.54 124.128 309.356 106.24 327.212 83.104C343.916 61.568 356.556 31.104 351.98 0Z"
            fill="black"
          />
          <path
            d="M459.852 171.776C433.644 138.912 396.812 119.84 362.028 119.84C316.108 119.84 296.684 141.824 264.78 141.824C231.884 141.824 206.892 119.904 167.18 119.904C128.172 119.904 86.6359 143.744 60.2999 184.512C23.2759 241.92 29.6119 349.856 89.6119 441.792C111.084 474.688 139.756 511.68 177.26 512C210.636 512.32 220.044 490.592 265.26 490.368C310.476 490.112 319.052 512.288 352.364 511.936C389.9 511.648 420.14 470.656 441.612 437.76C457.004 414.176 462.732 402.304 474.668 375.68C387.852 342.624 373.932 219.168 459.852 171.776Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_7_28">
            <rect width="512.003" height="512.003" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default function AppleLogoIcon({ fillColor }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AppleLogo sx={{ fontSize: "18px" }} />
    </Box>
  );
}
