import initializeStripe from "./initializeStripe";
import { firestore } from "../firebase";

const createCheckoutSession = async (url, plan, uid, referral) => {
  const stripe = await initializeStripe();
  const custRef = await firestore
    .collection("users")
    .doc(uid)
    .get()
    .then((res) => {
      if (res.exists) {
        return res.data();
      }
    });
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: referral
      ? JSON.stringify({
          subscriptionPrice: plan,
          stripeId: custRef.stripeId,
          email: custRef.email,
          referral: referral,
        })
      : JSON.stringify({
          subscriptionPrice: plan,
          stripeId: custRef.stripeId,
          email: custRef.email,
        }),
  });
  const session = await response.json();

  // When the customer clicks on the button, redirect them to Checkout.
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    console.log(result.error.message);
  }
};

export default createCheckoutSession;
