import { auth } from "../firebase";

export default async function subscriptionStatus() {
  await auth.currentUser.getIdToken(true);
  const decodedToken = await auth.currentUser?.getIdTokenResult();
  if (decodedToken?.claims?.subscriptionRole == "basic") {
    return { status: "basic", limit: 10 };
  } else if (decodedToken?.claims?.subscriptionRole == "standard") {
    return { status: "standard", limit: 20 };
  } else if (decodedToken?.claims?.subscriptionRole == "pro") {
    return { status: "pro", limit: 30 };
  } else return { status: "noData", limit: 1 };
}
