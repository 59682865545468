import React from "react";
import { Button } from "@mui/material";
import Tent from "./icons/tent";
import { modalLoginAtom } from "../utils/store";
import { useRouter } from "next/router";
import { useAtom } from "jotai";
import useMediaQuery from "@mui/material/useMediaQuery";
import TentTanIcon from "./icons/tent-tan";
import * as gtag from "../utils/gtag";

export default function ButtonGetStarted({
  user,
  hoverAlertButton,
  noIcon,
  title,
  handleMenuClose,
}) {
  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const router = useRouter();
  const smDown = useMediaQuery("(max-width:599px)", { noSsr: true });

  const handleLogin = () => {
    gtag.click("clicked_get_started");
    handleMenuClose && handleMenuClose();
    if (user) {
      router.push("/search");
    } else {
      smDown ? router.push("/login") : setModalLogin(true);
    }
  };

  return (
    <Button
      variant="outlined"
      sx={{
        width: "fit-content",
        boxShadow:
          "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        border: "1px solid #DDDDDD !important",
        color: "black",
        textTransform: "capitalize",
        "&:hover": {
          color: "black",
          border: "1px solid black !important",
        },
      }}
      onClick={handleLogin}
      startIcon={
        <TentTanIcon />
        // noIcon ? null : <Tent fillColor={hoverAlertButton ? "gray" : "black"} />
      }
    >
      {title ? title : "Get Started for free"}
    </Button>
  );
}
