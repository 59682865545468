import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";
// import useUserData from "../utils/hooks/use-user-data";
import Button from "@mui/material/Button";
import { auth, googleAuthProvider, appleAuthProvider } from "../utils/firebase";
import Link from "./nextjs-link";
import { IconButton } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { NextLinkComposed } from "./nextjs-link";
import MegaphoneIcon from "./icons/megaphone";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function DropdownAccordion({ handleMenuClose }) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>More</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MenuItem
            component={NextLinkComposed}
            to={{
              pathname: "/faq",
            }}
            onClick={handleMenuClose}
          >
            {/* <ListItemIcon>
              <CheckBoxOutlineBlankIcon
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            </ListItemIcon> */}
            <ListItemText>FAQ</ListItemText>
          </MenuItem>
          <MenuItem
            component={NextLinkComposed}
            to={{
              pathname: "/pricing",
            }}
            onClick={handleMenuClose}
          >
            <ListItemIcon>
              <CheckBoxOutlineBlankIcon
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText>Pricing</ListItemText>
          </MenuItem>
          <MenuItem
            component={NextLinkComposed}
            to={{
              pathname: "/about",
            }}
            onClick={handleMenuClose}
          >
            <ListItemIcon>
              <CheckBoxOutlineBlankIcon
                sx={{ color: "transparent" }}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText>About</ListItemText>
          </MenuItem>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
