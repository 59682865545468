import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore, firebase } from "../firebase";
// import useSubscriptionStatus from "../utils/stripe/useSubscriptionStatus";

export default function useUserData() {
  const [user, userLoading] = useAuthState(auth);
  const [subscriptionRole, setSubscriptionRole] = useState("noData");
  const [authIsReady, setAuthIsReady] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      setAuthIsReady(true);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // useEffect(() => {
  //   const checkPremiumStatus = async function () {
  //     await auth.currentUser?.getIdToken(true);
  //     const decodedToken = await auth.currentUser?.getIdTokenResult();
  //     console.log("decoded", decodedToken);
  //     setSubscriptionRole(decodedToken?.claims?.subscriptionRole);
  //   };
  //   checkPremiumStatus();
  // }, [user]);

  return { user, authIsReady };
}
