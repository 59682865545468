import { useEffect, useState } from "react";
import subscriptionStatus from "./subscriptionStatus";

export default function useSubscriptionStatus(user) {
  const [subStatus, setSubStatus] = useState({ status: "noData", limit: 0 });
  useEffect(() => {
    if (user) {
      const checkSubStatus = async function () {
        setSubStatus(await subscriptionStatus());
      };
      checkSubStatus();
    }
  }, [user]);
  return subStatus;
}
