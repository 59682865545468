import firebase from "firebase/compat/app";
import "firebase/compat/firestore"; //v9
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

const storage = firebase.storage();

const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");

const auth = firebase.auth();

export {
  firestore,
  firebase,
  googleAuthProvider,
  appleAuthProvider,
  auth,
  storage,
  functions,
};
