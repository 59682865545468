import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import useUserData from "../utils/hooks/use-user-data";
import Button from "@mui/material/Button";
import { auth } from "../utils/firebase";
import { Divider, IconButton } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useAtom } from "jotai";
import { NextLinkComposed } from "./nextjs-link";
import ListItemText from "@mui/material/ListItemText";
import ButtonGetStarted from "./button-get-started";
import { useRouter } from "next/router";
import useMediaQuery from "@mui/material/useMediaQuery";
import useUserData from "../utils/hooks/use-user-data";

import {
  modalLoginAtom,
  watchlistToggleAtom,
  modalWatchlistAtom,
} from "../utils/store";

export default function AccountPopoverLoggedOut() {
  // const { user, username } = useUserData();
  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const [watchListToggle, setWatchListToggle] = useAtom(watchlistToggleAtom);
  const [openWatchlist, setOpenWatchlist] = useAtom(modalWatchlistAtom);
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { user, username } = useUserData();

  const smDown = useMediaQuery("(max-width:599px)");

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleLogin = () => {
    handleMenuClose();
    if (user) {
      router.push("/search");
    } else {
      smDown ? router.push("/login") : setModalLogin(true);
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ marginTop: "3px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box>
        <MenuItem
          component={NextLinkComposed}
          to={{
            pathname: "/faq",
          }}
          onClick={handleMenuClose}
        >
          <ListItemText>FAQ</ListItemText>
        </MenuItem>
        <MenuItem
          component={NextLinkComposed}
          to={{
            pathname: "/pricing",
          }}
          onClick={handleMenuClose}
        >
          <ListItemText>Pricing</ListItemText>
        </MenuItem>
        <MenuItem
          component={NextLinkComposed}
          to={{
            pathname: "/about",
          }}
          onClick={handleMenuClose}
        >
          <ListItemText>About</ListItemText>
        </MenuItem>
        {/* <MenuItem
          component={NextLinkComposed}
          to={{
            pathname: "/affiliates",
          }}
          onClick={handleMenuClose}
        >
          <ListItemText>Affiliates</ListItemText>
        </MenuItem> */}
      </Box>
      <Divider
        variant="middle"
        sx={{ marginTop: "12px", marginBottom: "18px" }}
      />
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          width: "fit-content",
          marginLeft: "16px",
          marginRight: "16px",
          marginBottom: "12px",
        }}
      >
        <Button
          sx={{ color: "black", marginRight: "16px" }}
          onClick={handleLogin}
        >
          log in
        </Button>
        <ButtonGetStarted
          noIcon
          title="Sign Up"
          handleMenuClose={handleMenuClose}
        />
      </Stack>
    </Menu>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: { sm: "0px", md: "30px" },
      }}
    >
      <Button
        variant="outlined"
        sx={{
          background: "white",
          padding: "5px !important",
          width: "fit-content",
          boxShadow:
            "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
          border: "1px solid #DDDDDD !important",
          color: "black",
          "&:hover": {
            color: "black",
            border: "1px solid black !important",
          },
        }}
        onClick={handleProfileMenuOpen}
      >
        <MenuRoundedIcon sx={{ color: "black" }} />
      </Button>
      {renderMenu}
    </Box>
  );
}

// Sign in/Sign Up button
function SignInSignUpSignOutButton({
  handleMenuClose,
  user,
  setModalLogin,
  router,
}) {
  const handleSignOut = () => {
    handleMenuClose();
    auth.signOut();
    router.push("/");
  };
  const handleLogin = () => {
    setModalLogin(true);
    handleMenuClose();
  };
  return (
    <Button
      variant="outlined"
      sx={{
        // width: "fit-content",
        width: "150px",
        // marginLeft: "16px",
        // paddingRight: "16px",
        // marginRight: "16px",
        marginBottom: "8px",
        marginTop: "16px",
        boxShadow:
          "0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important",
        border: "1px solid #DDDDDD !important",
        color: "black",
        "&:hover": {
          color: "black",
          border: "1px solid black !important",
        },
      }}
      className="btn-signin-signup"
      onClick={() => (user ? handleSignOut() : handleLogin())}
    >
      {user ? "Sign Out" : "Sign In/Sign Up"}
    </Button>
  );
}
