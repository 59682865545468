// https://flaviocopes.com/nextjs-google-analytics/ Google Analytics GA added using this method
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import "../styles/globals.css";
import Layout from "../components/layout";
import Script from "next/script";
import { useJsApiLoader } from "@react-google-maps/api";
import { prevPathAtom } from "../utils/store";
import { useAtom } from "jotai";
import * as gtag from "../utils/gtag";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [prevPath, setPrevPath] = useAtom(prevPathAtom);

  const string = "/ca/ventura";

  function checkUppercase(str) {
    for (const i = 0; i < str.length; i++) {
      if (
        str.charAt(i) == str.charAt(i).toUpperCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (checkUppercase(router.pathname)) {
      router.push(`/${router.pathname.toLowerCase()}`);
    }

    // Rewardful https://www.reddit.com/r/reactjs/comments/tm1293/how_do_i_convert_html_script_tags_into_react_code/
    // (function (w, r) {
    //   w._rwq = r;
    //   w[r] =
    //     w[r] ||
    //     function () {
    //       (w[r].q = w[r].q || []).push(arguments);
    //     };
    // })(window, "rewardful");

    // let s = document.createElement("script");
    // s.async = true;
    // s.src = "https://r.wdfl.co/rw.js";
    // s.setAttribute("data-rewardful", process.env.REWARDFUL_KEY);
    // document.head.appendChild(s);
    // optionally remove the tag once you don't need them.
  }, []);

  useEffect(() => storePathValues, [router.asPath]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  function storePathValues() {
    // https://www.grouparoo.com/blog/getting-previous-path-nextjs
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPathSession = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPathSession);
    setPrevPath(prevPathSession);
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", globalThis.location.pathname);
  }

  // https://github.com/JustFly1984/react-google-maps-api/issues/238#issuecomment-761076533
  // prev did this: const libraries = ["places"];
  const [libraries] = useState(["places"]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  return (
    <Layout>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script
        id="google-analytics"
        strategy="lazyOnload"
      >{`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');`}</Script>

      <Component {...pageProps} isLoaded={isLoaded} />
    </Layout>
  );
}

export default MyApp;
