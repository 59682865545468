import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import IconApple from "./icon-apple";
import { auth, googleAuthProvider, appleAuthProvider } from "../utils/firebase";
import { useAtom } from "jotai";
import { modalLoginAtom, loadingSpinnerAtom } from "../utils/store";
import ButtonLoginApple from "./button-login-apple";
import ButtonLoginGoogle from "./button-login-google";
import Image from "next/image";

const style = {
  position: "absolute",
  top: "37%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "20px",
};

export default function LoginModal() {
  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const handleOpen = () => setModalLogin(true);
  const handleClose = () => setModalLogin(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={modalLogin}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: { xs: "28px", sm: "35px" },
                width: { xs: "120px", sm: "150px" },
                marginBottom: "16px",
              }}
            >
              <Image
                layout="fill"
                src="/logo-campwatch.svg"
                alt="Campwatch logo"
              />
            </Box>
            <ButtonLoginGoogle />
            <Box sx={{ height: "16px" }} />
            <ButtonLoginApple />
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
